// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// CSS
import '@fortawesome/fontawesome-free/css/all'
import 'tachyons/css/tachyons'
import './styles/global'

// JS
import Rails from '@rails/ujs'
import './global/alerts'

try {
  Rails.start()
} catch (error) {
  if (!error.message.includes('rails-ujs has already been loaded')) {
    throw error
  }
}
